import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ModalComponent } from './modal/modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from './pipes/pipe.pipe';
import { TimeDifference } from './pipes/pipe.pipe';
import { MinutesToDhmPipe } from './pipes/convert-minutes-to-dhm.pipe';
import { RidePanelComponent } from './ride-panel/ride-panel.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { GMapModule} from 'primeng/gmap'
import * as turf from '@turf/turf';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RatingModule} from 'primeng/rating';
import { RideService } from 'src/app/services/ride/ride.service';
import { FormsModule } from '@angular/forms';
import { PaymentPanelComponent } from './payment-panel/payment-panel.component';
import { PaymentHistoryPanelComponent } from './payment-history-panel/payment-history-panel.component';
import { RiderPanelComponent } from './rider-panel/rider-panel.component';
import { PickupNotesPanelComponent } from './pickup-notes-panel/pickup-notes-panel.component';
import { OverspeedingPanelComponent } from './overspeeding-panel/overspeeding-panel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecurrisionRidePanelComponent } from './recurrision-ride-panel/recurrision-ride-panel.component';
import {DialogModule} from 'primeng/dialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import { LiveridesRidePanelComponent } from './liverides-ride-panel/liverides-ride-panel.component';
import { DetailsPopupComponent } from './details-popup/details-popup.component';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';

@NgModule({
  declarations: [ModalComponent, DatePipe, TimeDifference, MinutesToDhmPipe, RidePanelComponent, PaymentPanelComponent, PaymentHistoryPanelComponent, RiderPanelComponent, OverspeedingPanelComponent, PickupNotesPanelComponent,RecurrisionRidePanelComponent,LiveridesRidePanelComponent,DetailsPopupComponent,CustomDropdownComponent, CustomTableComponent, CustomInputComponent, CustomSelectComponent],
  imports: [
    CommonModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDxSZHV6UPrJ_dEO2d4Kym_xlTog-jAmkU'
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),GMapModule,ProgressSpinnerModule,RatingModule,FormsModule, TableModule, ButtonModule,DialogModule, FileUploadModule,RadioButtonModule,DropdownModule,InputTextModule
  ],providers:[GoogleMapsAPIWrapper,RideService],
  exports: [ModalComponent, DatePipe, TimeDifference, MinutesToDhmPipe, RidePanelComponent,PaymentPanelComponent, PaymentHistoryPanelComponent,RiderPanelComponent,OverspeedingPanelComponent,PickupNotesPanelComponent,RecurrisionRidePanelComponent,LiveridesRidePanelComponent,DetailsPopupComponent,CustomDropdownComponent,CustomTableComponent,CustomInputComponent,CustomSelectComponent]
})
export class CommonsModule { }
